.primary-color {
  color: #ffcc00 !important;
}

.secondary-color {
  color: #8e8e8e !important;
}

.custom-gray {
  color: #a09c9c !important;
}

.primary-bg {
  background-color: #ffcc00 !important;
}

.secondary-bg {
  background-color: #000000 !important;
}

.insurance-bg {
  background-color: #fff5cc !important;
  border: 2px solid rgb(197 190 190 / 93%) !important;
  box-shadow: 8px 8px 10px #dfdedeed;
}

.faq-bg {
  background-color: #cecece;
}

.start-color {
  color: #f7931e !important;
}

.number-plate-color {
  color: #767571 !important;
}

.bg_white {
  background-color: #fff;
}

.custom-white {
  color: white;
}
