.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-box {
  position: absolute;
  bottom: 125%; /* Position above the target */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  max-width: 60vw;
  min-width: 20vw;
  width: auto;
  text-wrap: auto;
}

.tooltip-box::after {
  content: '';
  position: absolute;
  top: 100%; /* Arrow below the tooltip */
  left: 50.5%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}
.tooltip-container:hover .tooltip-box {
  opacity: 1;
}
