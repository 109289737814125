.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-btn {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #dee2e6;
  padding: 5px;
  height: 37px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}

.dropdown-btn:hover {
  background-color: #fecb00 !important;
  color: #000 !important;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: block;
}

.dropdown-content .dropdown-item {
  color: black;
  padding: 12px 16px;
  font-size: 13px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  text-wrap: balance !important;
  align-items: center;
  cursor: pointer;
}

.dropdown-content .dropdown-item:hover {
  background-color: #fecb00 !important;
  color: #000 !important;
}

.dropdown-item .checkmark {
  margin-left: 10px;
  font-weight: bold;
  color: white;
}

.dropdown-item.checked {
  background-color: #000 !important;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
}

.dropdown-item.checked:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.icon-font {
  font-size: 18px !important;
}
.content_min_height {
  min-height: calc(100vh - 346px);
}
