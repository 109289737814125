.custom-checkbox {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: black;
  border: 0px solid #FECB00;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.custom-checkbox::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  border: solid #FECB00 !important;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg) translate(-50%, -50%);
  display: none;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 15px;
  display: inline-block;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 13px;
  height: 13px;
  background: #000000;
  position: absolute; 
  top: 2.5px;
  left: 2.5px;
  border-radius: 10px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox {
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  width: 100%;
  flex-wrap: wrap;
}

.custom-checkbox .checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: black;
  border-radius: 4px;
  margin-right: 10px;
  flex-shrink: 0;
}

.custom-checkbox input[type="checkbox"]:checked+.checkmark:after {
  display: block;
  width: 6px;
  color: #000000;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 10px;
  width: 6px;
  height: 12px;
  border: 0px solid #FECB00;
  border-width: 0 3px 3px 0;
  transform: rotate(40deg);
}

.label-position {
  position: relative !important;
  bottom: 30px !important;
}

.custom-select-container {
  position: relative;
  display: inline-block;
  width: 200px;
}

.custom-select-trigger {
  display: block;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.custom-options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 1000;
  // max-height: 150px;
  overflow-y: auto;
}

.custom-option {
  padding: 10px;
  cursor: pointer;
}

.custom-option:hover {
  background-color: yellow;
  color: black;
}

.custom-select-container.open .custom-options {
  display: block;
}

.custom-input {
  height: 35px !important;
}

.form-control+div.text-danger {
  font-size: 14px !important;
}

.form-select+div.text-danger {
  font-size: 14px !important;
}

.w-36 {
  width: 36% !important;
}

.w-32 {
  width: 32% !important;
}

.w-30 {
  width: 30% !important;
}

@media (max-width:768px) {
  .w-36 {
    width: 100% !important;
  }

  .w-32 {
    width: 100% !important;
  }

  .w-30 {
    width: 100% !important;
  }
}