// @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");

body {
  // font-family: "Oswald", sans-serif;
  // font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

// @font-face {
//     font-family: 'Proxima Nova Bold';
//     src: url('./../../fonts/FontsFree-Net-Proxima-Nova-Bold.otf') format('opentype');
//     font-weight: normal !important;
//     font-style: normal;
// }
@font-face {
  font-family: "Proxima Nova Bold";
  src: url("./../../../../public/fonts/FontsFree-Net-Proxima-Nova-Bold.otf")
    format("opentype");
  font-weight: normal !important;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./../../../../public/fonts/Proxima\ Nova\ Font.otf")
    format("opentype");
  font-weight: normal !important;
  font-style: normal;
}

@font-face {
  font-family: "Minion Pro";
  src: url("./../../fonts/MinionPro-Regular.woff") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Nue Regular";
  src: url("./../../../../public/fonts/BebasNeue/Commercial/OTF/BebasNeue-Regular.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Nue Bold";
  src: url("./../../../../public/fonts/BebasNeue/Commercial/OTF/BebasNeue-Bold.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Nue pro Bold";
  src: url("./../../../../public/fonts/BebasNuePro/Bebas\ Neue\ Pro\ Bold.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Nue pro Regular";
  src: url("./../../../../public/fonts/BebasNuePro/Bebas\ Neue\ Pro\ Regular.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Nue pro Regular Italic";
  src: url("./../../../../public/fonts/BebasNuePro/Bebas Neue Pro Italic.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

.font-proxima-nova {
  // font-family: 'Proxima Nova Bold' !important;
  font-family: "Proxima Nova" !important;
}

.font-proxima-nova-regular {
  font-family: "Proxima Nova" !important;
}

.font-bebas {
  font-family: "Bebas Neue";
}

.font-bebas-regular {
  font-family: "Bebas Nue Regular";
}

.font-bebas-bold {
  font-family: "Bebas Nue Bold";
}

.font-bebas-nue-pro-bold {
  font-family: "Bebas Nue pro Bold";
}

.font-bebas-nue-pro-regular {
  font-family: "Bebas Nue pro Regular";
}

.font-bebas-nue-pro-regular-Italic {
  font-family: "Bebas Nue pro Regular Italic";
}

.font-Oswald {
  font-family: "Bebas Nue pro Regular" !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-45 {
  font-size: 45px !important;
}

.ls-1 {
  letter-spacing: 1px !important;
}

.ls-2 {
  letter-spacing: 2px !important;
}

.ls-3 {
  letter-spacing: 3px !important;
}

.ls-05 {
  letter-spacing: 0.5px !important;
}

.ws-1 {
  word-spacing: 1px !important;
}

@media (max-width: 767.99px) {
  .fs-m-15 {
    font-size: 15px !important;
  }

  .fs-m-20 {
    font-size: 14px !important;
  }
}
