.primary-button {
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
}

.primary-button-second {
  height: 44px !important;
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
}

.checkout-button {
  height: 48px !important;
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
}

.sort-button {
  background-color: #707070 !important;
}

.renewed-bg {
  background-color: #009f4a !important;
}
.secondary-button {
  background-color: #000000 !important;
  color: #fecb00 !important;
  height: 40px !important;
}

.primary-button_get_start {
  height: 38px !important;
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  // font-family: "Bebas Nue pro Regular" !important;
  background-color: #ffcc00 !important;
  color: #fff;
  padding: 20px 10px;
  border: 1px solid #a59c79 !important;
}

.primary-button {
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
  background-color: #000 !important;
  color: #ffcc00 !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  padding: 0px 10px 0px 10px !important;
}

.primary-button_second {
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  color: #b3b3b3 !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  padding: 0px 10px 0px 10px !important;
}

.primary-button_custom {
  height: 30px !important;
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
  background-color: white !important;
  border: 1px solid #ccc !important;
  color: #ccc !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  padding: 0px 10px 0px 10px !important;
}

.primary-button_custom_btn {
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
  color: #ffcc00 !important;
  background-color: #b3b3b3 !important;
  border: 1px solid #ccc !important;
  color: #fff !important;
  font-size: 14px !important;
  padding-top: 2px;
  line-height: 1px;
  height: 28px;
}

.primary-button-second {
  height: 44px !important;
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
}

.checkout-button {
  height: 30px !important;
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
  background-color: #00cc66 !important;
  color: #fff;
}

.order_status_button {
  height: 30px !important;
  background-color: #0077c0 !important;
  color: #fff !important;
}

.view_quotes_button {
  height: 30px !important;
  background-color: #fecb00 !important;
  color: #fff !important;
}

.sort-button {
  background-color: #707070 !important;
}

.renewed-bg {
  background-color: #009f4a !important;
}

.secondary-button {
  background-color: #000000 !important;
  color: #fecb00 !important;
  height: 32px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  padding: 0px 10px 1px 10px !important;
}

// ==========

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 18px;
  display: inline-block;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #000;
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  border-radius: 10px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark:after {
  display: block;
  width: 6px;
  color: #000000;
}

.form-check-input[type="checkbox"]:checked {
  background-color: #fff !important;
  color: #000000 !important;
  border-color: #ccc;
}
