.modal-show {
  display: block !important;
}

.modal-hide {
  display: none !important;
}

.modal-bg {
  border-radius: 10px !important;
  border: 0px !important;
  box-shadow: 1px 1px 10px black;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
}

.modal-custom {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000ab !important;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex) !important;
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg) !important;
}

.modal-backdrop.show {
  opacity: 1 !important;
}

.edit-shipping-modal {
  height: auto !important;
  overflow-y: auto !important;
  overflow-x: auto !important;
}