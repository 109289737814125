@import "./colors";
@import "./font-family";
@import "./fonts";
@import "./button";
@import "./form_input";
@import "./custom_drop_down";
@import "./modal";
@import "./spinner";

body {
  scrollbar-width: none !important;
}

.middle-border {
  border-right: 3px solid black;
  height: 80px;
}

.header-position {
  position: sticky;
  top: 0px;
  right: 0px;
  left: 0px;
  padding-top: 25px;
  z-index: 1 !important;
}

.button-padding {
  padding: 7px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.dmv-image {
  max-height: 75px;
  max-width: inherit !important;
  // width: 120px;
  // object-fit: contain !important;
}

.vehicle-image {
  height: 100px;
  width: 100px;
  object-fit: contain !important;
}

.dmv-bg-card {
  height: 100px !important;
}

.shipping-dmv-image {
  max-height: 100px;
  // width: 160px;
  max-width: inherit !important;
}

.credit-card-image {
  max-width: inherit !important;
  height: 40px;
}

.custom-max-width {
  max-width: inherit !important;
}

.form-check-input[type="checkbox"] {
  height: 25px !important;
  width: 25px !important;
}

// .form-check-input[type="checkbox"]:checked {
//   background-color: #000000 !important;
//   color: #000000 !important;
// }

.form-check-input:checked[type="checkbox"] {
  color: #000000 !important;
}

.form-check-input[type="radio"]:checked {
  background-color: #000000 !important;
  color: #000000 !important;
  border-color: #000000 !important;
  border-radius: 10px !important;
}

.footer-logo-image {
  width: 180px !important;
  height: 120px !important;
}

.navbar {
  --bs-navbar-toggler-icon-bg: url("./../../images/mobile-menu.jpg") !important;
  border: 0px transparent solid !important;
}

.list-style-type-none {
  list-style-type: none !important;
}

.privacy-policy thead tr th,
tbody tr td {
  padding: 20px;
  align-items: center;
}

.privacy-policy tbody tr td {
  padding: 25px;
  align-items: center;
}

.list-style li {
  list-style-type: circle !important;
  margin-bottom: 10px;
}

.list-style li::marker {
  list-style-type: none !important;
}

.login-container {
  height: calc(100vh - 241px) !important;
  overflow-y: scroll !important;
}

.our-services-image {
  width: 80px !important;
  height: 80px !important;
  object-fit: contain !important;
}

.side-menu {
  width: 200px;
  padding: 20px;
}

.side-menu ul {
  list-style-type: none;
  padding: 0;
}

.side-menu li {
  list-style-type: none;
  margin: 10px 0;
}

.side-menu ul li a.active {
  font-weight: bold;
}

.side-menu ul li a:hover {
  font-weight: bold;
}

.side-menu a {
  text-decoration: none;
  color: #333;
}

.side-menu a:hover {
  text-decoration: underline;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
  color: #000 !important;
}

.service-card {
  min-height: 180px !important;
  background-color: #f0f0f0 !important;
  box-shadow: 8px 8px 10px #dfdedeed;
}

.online-service-drop-down {
  // right: 4% !important;
  right: -90% !important;
}

.support-drop-down {
  // right: 4% !important;
  right: -90% !important;
}

.profile-image {
  height: 200px;
  width: 200px;
  object-fit: cover !important;
  border-radius: 100px !important;
}

.homepage-content-overflow {
  overflow-x: clip !important;
}

.list-style-dot {
  list-style-type: disc !important;
}

@media (max-width: 767.99px) {
  .vehicle-image {
    height: 70px !important;
    width: 80px !important;
    object-fit: contain;
  }

  .header-text {
    font-size: 10px !important;
  }

  .dmv-bg-card {
    height: 200px !important;
  }

  .footer-logo-image {
    width: 75px !important;
    height: 43px !important;
  }

  .side-menu {
    width: 100px !important;
    padding: 10px !important;
  }

  .navbar-toggler {
    background-color: transparent !important;
    border: 0px solid transparent !important;
  }

  .menu_mobile {
    position: absolute;
    top: 50%;
    right: 2%;

    ul.navbar-nav {
      display: inline-block;
      padding: 15px;
    }
  }

  .header-profile-image {
    position: relative;
    bottom: 55px;
    height: 40px;
    width: 40px;
    border-radius: 40px !important;
  }

  .mobile-drop-down {
    position: relative !important;
    width: 50%;
    top: -50px !important;
  }

  .header-position {
    position: sticky;
    top: 0px;
    right: 0px;
    left: 0px;
    padding-top: 0px;
    z-index: 1 !important;
  }

  .header-logo {
    height: 120px !important;
    width: 120px !important;
    position: relative !important;
    // bottom:25px  !important;
  }

  .shipping-dmv-image {
    max-height: 160px;
    // width: 270px;
    max-width: inherit !important;
  }

  .mobile_bg_primary {
    background-color: #ffcc00 !important;
  }

  .mobile_dashboard {
    margin-top: 60px;
    background: white !important;
    border-radius: 25px;
  }

  .mobile_hamburger {
    position: relative !important;
    z-index: 12 !important;
  }

  .mobile_shadow {
    box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .mobile-login {
    display: block;
    width: 70px !important;
  }

  .header-logo-caption {
    position: relative;
    bottom: 15px !important;
    right: 15px;
  }

  .hamburger-icon {
    position: relative !important;
    top: 35px !important;
  }
}

// .form-check-input:checked[type="checkbox"] {
//   --bs-form-check-bg-image: url("../../images/yellow_check_mark.png") !important;
//   background-image: url("../../images/yellow_check_mark.png") !important;
// }

.spinner {
  height: 125px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

option:checked,
option:hover {
  box-shadow: 0 0 10px 100px #4ddc3b inset !important;
}

select option:hover {
  background-color: yellow !important;
}

.header-profile-image {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.header-logo {
  height: 100px;
  width: 150px;
}

.custom-border-width {
  border-width: 1.5px !important;
}

.header-logo-caption {
  position: relative;
  bottom: 10px;
  right: 15px;
}

.vehicle-service-image {
  height: 150px;
  width: 120px;
}

.process-order-position {
  position: relative !important;
  left: 20% !important;
}

label.form-label {
  font-size: 14px !important;
}

.letter-spacing-0-5 {
  letter-spacing: 0.5px;
}

.bg_gray {
  background: #c8c6c6cf !important;
}

.bg_dark_gray {
  background: #f0f0f0 !important;
}
select.form-control.custom-input.bg_gray {
  appearance: auto !important;
}
.text_light_color {
  color: rgb(105 105 105 / 67%);
}
.text_little_dark_color {
  color: #919191;
}

/* Switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

/* Hide the default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider (background and circle) */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Switch active state */
input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider::before {
  transform: translateX(26px);
}

/* Optional hover effect */
.switch:hover .slider {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Add focus ring for accessibility */
input:focus + .slider {
  box-shadow: 0 0 2px 2px #2196f3;
}

/*# sourceMappingURL=styles.css.map */
.footer_content {
  border: 1px solid white;
  /* margin: 10px 0px; */
  border-left: 0px;
  border-right: 0px;
}

.footer_content_text {
  border-left: 1px solid white;
  padding: 10px;
}
table.table {
  text-align: center;
}

.custom_border_2 {
  border: 1px solid #79776d;
  box-shadow:0px 8px 10px #C79F03;
}

.address_custom_width {
  width: 170px !important;
}
.modal-header {
  border-bottom: none !important;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("../../images/checkmark.png") !important;
  background-image: url("../../images/checkmark.png") !important;
  background-size: 16px;
}
.color_black {
  color: black !important;
}

.get_start_btn {
  top: 0px;
  right: 0px;
}

select.form-control.custom-input.bg_dark_gray {
  appearance: auto !important;
}