@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
.primary-color {
  color: #ffcc00 !important;
}

.secondary-color {
  color: #8e8e8e !important;
}

.custom-gray {
  color: #a09c9c !important;
}

.primary-bg {
  background-color: #ffcc00 !important;
}

.secondary-bg {
  background-color: #000000 !important;
}

.insurance-bg {
  background-color: #fff5cc !important;
  border: 2px solid rgba(197, 190, 190, 0.93) !important;
  box-shadow: 8px 8px 10px rgba(223, 222, 222, 0.9294117647);
}

.faq-bg {
  background-color: #cecece;
}

.start-color {
  color: #f7931e !important;
}

.number-plate-color {
  color: #767571 !important;
}

.bg_white {
  background-color: #fff;
}

.custom-white {
  color: white;
}

body {
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: url("./../../../../public/fonts/FontsFree-Net-Proxima-Nova-Bold.otf") format("opentype");
  font-weight: normal !important;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("./../../../../public/fonts/Proxima Nova Font.otf") format("opentype");
  font-weight: normal !important;
  font-style: normal;
}
@font-face {
  font-family: "Minion Pro";
  src: url("./../../fonts/MinionPro-Regular.woff") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bebas Nue Regular";
  src: url("./../../../../public/fonts/BebasNeue/Commercial/OTF/BebasNeue-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bebas Nue Bold";
  src: url("./../../../../public/fonts/BebasNeue/Commercial/OTF/BebasNeue-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bebas Nue pro Bold";
  src: url("./../../../../public/fonts/BebasNuePro/Bebas Neue Pro Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bebas Nue pro Regular";
  src: url("./../../../../public/fonts/BebasNuePro/Bebas Neue Pro Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bebas Nue pro Regular Italic";
  src: url("./../../../../public/fonts/BebasNuePro/Bebas Neue Pro Italic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
.font-proxima-nova {
  font-family: "Proxima Nova" !important;
}

.font-proxima-nova-regular {
  font-family: "Proxima Nova" !important;
}

.font-bebas {
  font-family: "Bebas Neue";
}

.font-bebas-regular {
  font-family: "Bebas Nue Regular";
}

.font-bebas-bold {
  font-family: "Bebas Nue Bold";
}

.font-bebas-nue-pro-bold {
  font-family: "Bebas Nue pro Bold";
}

.font-bebas-nue-pro-regular {
  font-family: "Bebas Nue pro Regular";
}

.font-bebas-nue-pro-regular-Italic {
  font-family: "Bebas Nue pro Regular Italic";
}

.font-Oswald {
  font-family: "Bebas Nue pro Regular" !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-45 {
  font-size: 45px !important;
}

.ls-1 {
  letter-spacing: 1px !important;
}

.ls-2 {
  letter-spacing: 2px !important;
}

.ls-3 {
  letter-spacing: 3px !important;
}

.ls-05 {
  letter-spacing: 0.5px !important;
}

.ws-1 {
  word-spacing: 1px !important;
}

@media (max-width: 767.99px) {
  .fs-m-15 {
    font-size: 15px !important;
  }
  .fs-m-20 {
    font-size: 14px !important;
  }
}
.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fw-700 {
  font-weight: 700;
}

.w-40 {
  width: 40%;
}

.primary-button {
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
}

.primary-button-second {
  height: 44px !important;
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
}

.checkout-button {
  height: 48px !important;
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
}

.sort-button {
  background-color: #707070 !important;
}

.renewed-bg {
  background-color: #009f4a !important;
}

.secondary-button {
  background-color: #000000 !important;
  color: #fecb00 !important;
  height: 40px !important;
}

.primary-button_get_start {
  height: 38px !important;
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  background-color: #ffcc00 !important;
  color: #fff;
  padding: 20px 10px;
  border: 1px solid #a59c79 !important;
}

.primary-button {
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
  background-color: #000 !important;
  color: #ffcc00 !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  padding: 0px 10px 0px 10px !important;
}

.primary-button_second {
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  color: #b3b3b3 !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  padding: 0px 10px 0px 10px !important;
}

.primary-button_custom {
  height: 30px !important;
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
  background-color: white !important;
  border: 1px solid #ccc !important;
  color: #ccc !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  padding: 0px 10px 0px 10px !important;
}

.primary-button_custom_btn {
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
  color: #ffcc00 !important;
  background-color: #b3b3b3 !important;
  border: 1px solid #ccc !important;
  color: #fff !important;
  font-size: 14px !important;
  padding-top: 2px;
  line-height: 1px;
  height: 28px;
}

.primary-button-second {
  height: 44px !important;
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
}

.checkout-button {
  height: 30px !important;
  letter-spacing: 1px !important;
  border-radius: 3px !important;
  font-family: "Bebas Nue pro Regular" !important;
  background-color: #00cc66 !important;
  color: #fff;
}

.order_status_button {
  height: 30px !important;
  background-color: #0077c0 !important;
  color: #fff !important;
}

.view_quotes_button {
  height: 30px !important;
  background-color: #fecb00 !important;
  color: #fff !important;
}

.sort-button {
  background-color: #707070 !important;
}

.renewed-bg {
  background-color: #009f4a !important;
}

.secondary-button {
  background-color: #000000 !important;
  color: #fecb00 !important;
  height: 32px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  padding: 0px 10px 1px 10px !important;
}

[type=radio]:checked,
[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type=radio]:checked + label,
[type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 18px;
  display: inline-block;
}

[type=radio]:checked + label:before,
[type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 100%;
  background: #fff;
}

[type=radio]:checked + label:after,
[type=radio]:not(:checked) + label:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #000;
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  border-radius: 10px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type=radio]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type=radio]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.custom-checkbox input[type=checkbox]:checked + .checkmark:after {
  display: block;
  width: 6px;
  color: #000000;
}

.form-check-input[type=checkbox]:checked {
  background-color: #fff !important;
  color: #000000 !important;
  border-color: #ccc;
}

.custom-checkbox {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: black;
  border: 0px solid #FECB00;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.custom-checkbox::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  border: solid #FECB00 !important;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg) translate(-50%, -50%);
  display: none;
}

[type=radio]:checked,
[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type=radio]:checked + label,
[type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 15px;
  display: inline-block;
}

[type=radio]:checked + label:before,
[type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 100%;
  background: #fff;
}

[type=radio]:checked + label:after,
[type=radio]:not(:checked) + label:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #000000;
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  border-radius: 10px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type=radio]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type=radio]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.custom-checkbox input[type=checkbox] {
  display: none;
}

.custom-checkbox {
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  width: 100%;
  flex-wrap: wrap;
}

.custom-checkbox .checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: black;
  border-radius: 4px;
  margin-right: 10px;
  flex-shrink: 0;
}

.custom-checkbox input[type=checkbox]:checked + .checkmark:after {
  display: block;
  width: 6px;
  color: #000000;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 10px;
  width: 6px;
  height: 12px;
  border: 0px solid #FECB00;
  border-width: 0 3px 3px 0;
  transform: rotate(40deg);
}

.label-position {
  position: relative !important;
  bottom: 30px !important;
}

.custom-select-container {
  position: relative;
  display: inline-block;
  width: 200px;
}

.custom-select-trigger {
  display: block;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.custom-options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 1000;
  overflow-y: auto;
}

.custom-option {
  padding: 10px;
  cursor: pointer;
}

.custom-option:hover {
  background-color: yellow;
  color: black;
}

.custom-select-container.open .custom-options {
  display: block;
}

.custom-input {
  height: 35px !important;
}

.form-control + div.text-danger {
  font-size: 14px !important;
}

.form-select + div.text-danger {
  font-size: 14px !important;
}

.w-36 {
  width: 36% !important;
}

.w-32 {
  width: 32% !important;
}

.w-30 {
  width: 30% !important;
}

@media (max-width: 768px) {
  .w-36 {
    width: 100% !important;
  }
  .w-32 {
    width: 100% !important;
  }
  .w-30 {
    width: 100% !important;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-btn {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #dee2e6;
  padding: 5px;
  height: 37px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}

.dropdown-btn:hover {
  background-color: #fecb00 !important;
  color: #000 !important;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: block;
}

.dropdown-content .dropdown-item {
  color: black;
  padding: 12px 16px;
  font-size: 13px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  text-wrap: balance !important;
  align-items: center;
  cursor: pointer;
}

.dropdown-content .dropdown-item:hover {
  background-color: #fecb00 !important;
  color: #000 !important;
}

.dropdown-item .checkmark {
  margin-left: 10px;
  font-weight: bold;
  color: white;
}

.dropdown-item.checked {
  background-color: #000 !important;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
}

.dropdown-item.checked:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.icon-font {
  font-size: 18px !important;
}

.content_min_height {
  min-height: calc(100vh - 346px);
}

.modal-show {
  display: block !important;
}

.modal-hide {
  display: none !important;
}

.modal-bg {
  border-radius: 10px !important;
  border: 0px !important;
  box-shadow: 1px 1px 10px black;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
}

.modal-custom {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000ab !important;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex) !important;
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg) !important;
}

.modal-backdrop.show {
  opacity: 1 !important;
}

.edit-shipping-modal {
  height: auto !important;
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.custom_spinner {
  position: fixed;
  top: 0;
  left: 0px;
  height: 100vh;
  width: calc(100vw - 0px) !important;
  background: rgba(0, 0, 0, 0.4509803922);
  z-index: 25;
}

.custom_spinner .spinner-border {
  position: absolute;
  top: 50%;
  color: white;
}

@media (max-width: 767.99px) {
  .custom_spinner {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: calc(100vw - 0px) !important;
    background: rgba(0, 0, 0, 0.7490196078);
    z-index: 25;
  }
}
body {
  scrollbar-width: none !important;
}

.middle-border {
  border-right: 3px solid black;
  height: 80px;
}

.header-position {
  position: sticky;
  top: 0px;
  right: 0px;
  left: 0px;
  padding-top: 25px;
  z-index: 1 !important;
}

.button-padding {
  padding: 7px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.dmv-image {
  max-height: 75px;
  max-width: inherit !important;
}

.vehicle-image {
  height: 100px;
  width: 100px;
  object-fit: contain !important;
}

.dmv-bg-card {
  height: 100px !important;
}

.shipping-dmv-image {
  max-height: 100px;
  max-width: inherit !important;
}

.credit-card-image {
  max-width: inherit !important;
  height: 40px;
}

.custom-max-width {
  max-width: inherit !important;
}

.form-check-input[type=checkbox] {
  height: 25px !important;
  width: 25px !important;
}

.form-check-input:checked[type=checkbox] {
  color: #000000 !important;
}

.form-check-input[type=radio]:checked {
  background-color: #000000 !important;
  color: #000000 !important;
  border-color: #000000 !important;
  border-radius: 10px !important;
}

.footer-logo-image {
  width: 180px !important;
  height: 120px !important;
}

.navbar {
  --bs-navbar-toggler-icon-bg: url("./../../images/mobile-menu.jpg") !important;
  border: 0px transparent solid !important;
}

.list-style-type-none {
  list-style-type: none !important;
}

.privacy-policy thead tr th,
tbody tr td {
  padding: 20px;
  align-items: center;
}

.privacy-policy tbody tr td {
  padding: 25px;
  align-items: center;
}

.list-style li {
  list-style-type: circle !important;
  margin-bottom: 10px;
}

.list-style li::marker {
  list-style-type: none !important;
}

.login-container {
  height: calc(100vh - 241px) !important;
  overflow-y: scroll !important;
}

.our-services-image {
  width: 80px !important;
  height: 80px !important;
  object-fit: contain !important;
}

.side-menu {
  width: 200px;
  padding: 20px;
}

.side-menu ul {
  list-style-type: none;
  padding: 0;
}

.side-menu li {
  list-style-type: none;
  margin: 10px 0;
}

.side-menu ul li a.active {
  font-weight: bold;
}

.side-menu ul li a:hover {
  font-weight: bold;
}

.side-menu a {
  text-decoration: none;
  color: #333;
}

.side-menu a:hover {
  text-decoration: underline;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
  color: #000 !important;
}

.service-card {
  min-height: 180px !important;
  background-color: #f0f0f0 !important;
  box-shadow: 8px 8px 10px rgba(223, 222, 222, 0.9294117647);
}

.online-service-drop-down {
  right: -90% !important;
}

.support-drop-down {
  right: -90% !important;
}

.profile-image {
  height: 200px;
  width: 200px;
  object-fit: cover !important;
  border-radius: 100px !important;
}

.homepage-content-overflow {
  overflow-x: clip !important;
}

.list-style-dot {
  list-style-type: disc !important;
}

@media (max-width: 767.99px) {
  .vehicle-image {
    height: 70px !important;
    width: 80px !important;
    object-fit: contain;
  }
  .header-text {
    font-size: 10px !important;
  }
  .dmv-bg-card {
    height: 200px !important;
  }
  .footer-logo-image {
    width: 75px !important;
    height: 43px !important;
  }
  .side-menu {
    width: 100px !important;
    padding: 10px !important;
  }
  .navbar-toggler {
    background-color: transparent !important;
    border: 0px solid transparent !important;
  }
  .menu_mobile {
    position: absolute;
    top: 50%;
    right: 2%;
  }
  .menu_mobile ul.navbar-nav {
    display: inline-block;
    padding: 15px;
  }
  .header-profile-image {
    position: relative;
    bottom: 55px;
    height: 40px;
    width: 40px;
    border-radius: 40px !important;
  }
  .mobile-drop-down {
    position: relative !important;
    width: 50%;
    top: -50px !important;
  }
  .header-position {
    position: sticky;
    top: 0px;
    right: 0px;
    left: 0px;
    padding-top: 0px;
    z-index: 1 !important;
  }
  .header-logo {
    height: 120px !important;
    width: 120px !important;
    position: relative !important;
  }
  .shipping-dmv-image {
    max-height: 160px;
    max-width: inherit !important;
  }
  .mobile_bg_primary {
    background-color: #ffcc00 !important;
  }
  .mobile_dashboard {
    margin-top: 60px;
    background: white !important;
    border-radius: 25px;
  }
  .mobile_hamburger {
    position: relative !important;
    z-index: 12 !important;
  }
  .mobile_shadow {
    box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .mobile-login {
    display: block;
    width: 70px !important;
  }
  .header-logo-caption {
    position: relative;
    bottom: 15px !important;
    right: 15px;
  }
  .hamburger-icon {
    position: relative !important;
    top: 35px !important;
  }
}
.spinner {
  height: 125px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

option:checked,
option:hover {
  box-shadow: 0 0 10px 100px #4ddc3b inset !important;
}

select option:hover {
  background-color: yellow !important;
}

.header-profile-image {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.header-logo {
  height: 100px;
  width: 150px;
}

.custom-border-width {
  border-width: 1.5px !important;
}

.header-logo-caption {
  position: relative;
  bottom: 10px;
  right: 15px;
}

.vehicle-service-image {
  height: 150px;
  width: 120px;
}

.process-order-position {
  position: relative !important;
  left: 20% !important;
}

label.form-label {
  font-size: 14px !important;
}

.letter-spacing-0-5 {
  letter-spacing: 0.5px;
}

.bg_gray {
  background: rgba(200, 198, 198, 0.8117647059) !important;
}

.bg_dark_gray {
  background: #f0f0f0 !important;
}

select.form-control.custom-input.bg_gray {
  appearance: auto !important;
}

.text_light_color {
  color: rgba(105, 105, 105, 0.67);
}

.text_little_dark_color {
  color: #919191;
}

/* Switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

/* Hide the default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider (background and circle) */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Switch active state */
input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider::before {
  transform: translateX(26px);
}

/* Optional hover effect */
.switch:hover .slider {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Add focus ring for accessibility */
input:focus + .slider {
  box-shadow: 0 0 2px 2px #2196f3;
}


.footer_content {
  border: 1px solid white;
  /* margin: 10px 0px; */
  border-left: 0px;
  border-right: 0px;
}

.footer_content_text {
  border-left: 1px solid white;
  padding: 10px;
}

table.table {
  text-align: center;
}

.custom_border_2 {
  border: 1px solid #79776d;
  box-shadow: 0px 8px 10px #C79F03;
}

.address_custom_width {
  width: 170px !important;
}

.modal-header {
  border-bottom: none !important;
}

.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("../../images/checkmark.png") !important;
  background-image: url("../../images/checkmark.png") !important;
  background-size: 16px;
}

.color_black {
  color: black !important;
}

.get_start_btn {
  top: 0px;
  right: 0px;
}

select.form-control.custom-input.bg_dark_gray {
  appearance: auto !important;
}

