.custom_spinner {
    position: fixed;
    top: 0;
    left: 0px;
    height: 100vh;
    width: calc(100vw - 0px) !important;
    background: #00000073;
    z-index: 25;
}

.custom_spinner .spinner-border {
    position: absolute;
    top: 50%;
    color: white;
}

@media (max-width: 767.99px) {
    .custom_spinner {
        position: fixed;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: calc(100vw - 0px) !important;
        background: #000000bf;
        z-index: 25;
    }
}